import { scaleIn, scaleOut } from '@theme/animations';
import styled, { css } from 'styled-components';

import { MouseEvent } from 'react';

export interface IconProps {
  name: string;
  color?: string;
  size?: number;
  rotation?: number;
  onTouch?: boolean;
  $clickable?: boolean;
  margin?: string;
  indentLess?: boolean;
  scaleX?: string;
  testId?: string;
  onClick?: (ev: MouseEvent<HTMLElement>) => void;
  onMouseOver?: (ev: MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (ev: MouseEvent<HTMLElement>) => void;
  $hoverColor?: string;
  $hoverEffect?: boolean;
  $hoverScale?: boolean;
  $enableHoverScale?: boolean;
}

const Ic = styled.i<{
  size?: number;
  color?: string;
  $clickable?: boolean;
  width?: number;
  height?: number;
  $rotation: number;
  margin?: string;
  scaleX?: string;
  $hoverColor?: string;
  $hoverEffect?: boolean;
  $hoverScale?: boolean;
  $enableHoverScale?: boolean;
}>`
  font-size: ${props => (props?.size ?? 0) * 0.1}rem;
  ${props => props.$clickable && `cursor: pointer;`}

  &:active {
    opacity: 0.4;
  }
  ${props =>
    props.$hoverEffect &&
    ` &:hover {
      opacity: 0.4;
      color: ${props.theme.color.primary};
      transition: all 0.2s linear;
    } 
     `};

  &:before {
    color: ${props => props.color ?? props.theme.color.white};
  }
  ${props =>
    props.$hoverColor &&
    `&:hover {
      opacity: 1;
      color:${props.$hoverColor}; 
     &:before {
      transition: all 0.2s linear;
      color: unset;
  }}
  `};
  height: ${props => props.height && `${props.height}rem`};
  width: ${props => props.width && `${props.width}rem`};
  justify-content: center;
  align-items: center;
  display: flex;
  margin: ${props => props.margin};
  ${props => props.$rotation && `transform: rotate(${props.$rotation}deg)`};
  ${props =>
    props.$rotation && props.scaleX && `transform: scaleX(${props.scaleX})`};
  ${props =>
    props.$enableHoverScale
      ? props.$hoverScale
        ? css`
            animation: ${scaleIn} 500ms ease-in forwards;
          `
        : css`
            animation: ${scaleOut} 500ms ease-in forwards;
          `
      : null};
`;

function Icon({
  name,
  color,
  size = 22,
  rotation = 0,
  onClick,
  onMouseOver,
  onMouseLeave,
  $clickable = false,
  margin,
  indentLess,
  scaleX,
  testId,
  $hoverColor,
  $hoverEffect,
  $hoverScale,
  $enableHoverScale,
}: IconProps) {
  return (
    <Ic
      data-testid={testId ?? 'components-icon'}
      className={`icon-${name}`}
      color={color}
      size={size}
      width={!indentLess ? size / 5 : size / 10}
      height={!indentLess ? size / 5 : size / 10}
      onClick={onClick}
      $clickable={$clickable || !(onClick == null)}
      onMouseOver={ev => onMouseOver?.(ev)}
      onMouseLeave={ev => onMouseLeave?.(ev)}
      $rotation={rotation}
      margin={margin}
      scaleX={scaleX}
      $hoverColor={$hoverColor}
      $hoverEffect={$hoverEffect}
      $hoverScale={$hoverScale}
      $enableHoverScale={$enableHoverScale}
    />
  );
}

export default Icon;
